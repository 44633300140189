@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q, blockquote {
  quotes: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

a img {
  border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
  visibility: hidden; }

.pc-on {
  display: block; }

.sp-on {
  display: none; }

body {
  position: relative;
  font-family: 'Noto Sans JP', 'Hiragino Kaku Gothic ProN', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, YuGothic, Arial, "メイリオ", Meiryo, sans-serif;
  font-size: 16px;
  color: #565656; }

a {
  text-decoration: none;
  transition: all 0.5s; }
  a img {
    transition: opacity 0.5s;
    backface-visibility: hidden; }
  a:hover {
    opacity: 0.7;
    text-decoration: none; }
    a:hover img {
      opacity: 0.7; }

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="button"],
input[type="submit"],
input[type="password"],
button,
textarea,
select {
  appearance: none;
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none; }

@media screen and (max-width: 769px) {
  .pc-on {
    display: none; }
  .sp-on {
    display: block; } }

img {
  max-width: 100%;
  height: auto; }

a {
  color: #000000; }

.header {
  padding: 60px 0 50px;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 1340px; }
  .header .logo {
    width: 150px;
    height: 40px;
    margin: 0 72px; }

@media screen and (max-width: 769px) {
  .header {
    padding: 40px 0;
    margin: 0 7%; }
    .header .logo {
      margin: 0; } }

.kv {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

#home .kv {
  background-image: url(../img/home.jpg); }

#order .kv {
  background-image: url(../img/order.jpg); }

.content {
  margin: auto;
  max-width: 1340px; }
  .content .text {
    width: 33%;
    padding: 0 80px;
    font-size: 14px;
    line-height: 2;
    color: #222; }
    .content .text span {
      text-decoration: underline; }
      .content .text span:hover {
        opacity: 0.8;
        cursor: pointer; }
    .content .text.fadein-act {
      transition-delay: 0.5s; }

.popup {
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  padding: 30px;
  box-sizing: border-box;
  transition: opacity 1s, visibility 0s ease 1s; }
  .popup .close-button {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    color: #fff;
    position: relative;
    text-align: right;
    max-width: 1072px;
    padding: 0 21px;
    margin: 0 auto 10px; }
    .popup .close-button::after {
      content: "×";
      font-size: 30px;
      position: absolute;
      top: -9px;
      right: 0; }
  .popup .box {
    background: #fff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    margin: auto;
    max-width: 1072px; }
  .popup.open {
    visibility: visible;
    opacity: 1;
    transition: ease 1s; }

.footer {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1340px;
  margin: auto; }
  .footer nav {
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .footer ul {
    margin: 0 80px;
    display: flex; }
  .footer li {
    font-family: 'Nunito', sans-serif;
    font-size: 20px;
    color: #000000;
    margin-right: 50px;
    line-height: 1.35; }
    .footer li img {
      width: 16px; }
    .footer li:last-child {
      margin: 0;
      display: flex; }
    .footer li.fadein-act {
      transition-delay: 1.5s; }
      .footer li.fadein-act:nth-child(2) {
        transition-delay: 1.7s; }
      .footer li.fadein-act:nth-child(3) {
        transition-delay: 1.9s; }
      .footer li.fadein-act:nth-child(4) {
        transition-delay: 2.1s; }

.fadein {
  transition: transform 1s, opacity 1s;
  transform: translate3d(0, 10px, 0);
  opacity: 0; }

.fadein-act {
  transform: translate3d(0, 0, 0);
  opacity: 1; }

@media screen and (max-width: 769px) {
  #home .kv {
    background-image: url(../img/home-sp.jpg);
    height: auto; }
  #order .kv {
    background-image: url(../img/order-sp.jpg);
    height: auto; }
  .content {
    margin: 0 8% 20px;
    width: auto; }
    .content .text {
      width: 100%;
      padding: 0; }
  .footer {
    bottom: 0;
    position: fixed; }
    .footer nav {
      background-color: rgba(230, 225, 226, 0.85);
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
      padding: 14px 0 12px;
      opacity: 0; }
      .footer nav.fadein-act {
        transition-delay: 1s;
        opacity: 1; }
    .footer ul {
      width: 297px;
      margin: auto;
      justify-content: space-around; }
    .footer li {
      font-family: 'Nunito', sans-serif;
      font-size: 18px;
      margin-right: 40px; } }
