$primary: #4285F4;
$secondary: #FBBC05;
$accent: #EA4335;
$background: #F7F7F7;

$seo: #1FD080;
$seodark: #3AC184;
$seosec: #0C6374;

$white: #fff;
$black: #333;
$gray: #565656;
