@charset "UTF-8";

img {
  max-width: 100%;
  height: auto;
}

a {
  color: #000000;
}

.header {
  padding: 60px 0 50px;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 1340px;
  .logo {
    width: 150px;
    height: 40px;
    margin: 0 72px;
  }
}

@include sp-screen {
  .header {
    padding: 40px 0;
    margin: 0 7%;
    .logo {
      margin: 0;
    }
  }
}

.kv {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#home .kv {
  background-image: url(../img/home.jpg);
}

#order .kv{
  background-image: url(../img/order.jpg);
}

.content {
  margin: auto;
  max-width: 1340px;
  .text {
    width: 33%;
    padding: 0 80px;
    font-size: 14px;
    line-height: 2;
    color: #222;
    span {
      text-decoration: underline;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
    &.fadein-act {
      transition-delay: 0.5s;
    }
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(#000000,0.7);
  opacity: 0;
  padding: 30px;
  box-sizing: border-box;
  transition: opacity 1s, visibility 0s ease 1s;
  .close-button {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    color: #fff;
    position: relative;
    text-align: right;
    max-width: 1072px;
    padding: 0 21px;
    margin: 0 auto 10px;
    &::after {
      content: "×";
      font-size: 30px;
      position: absolute;
      top: -9px;
      right: 0;
    }
  }
  .box {
    background: #fff;
    box-shadow: 0 0 6px rgba(#000000,0.16);
    padding: 20px;
    margin: auto;
    max-width: 1072px;
  }
  &.open {
    visibility: visible;
    opacity: 1;
    transition: ease 1s;
  }
}

.footer {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1340px;
  margin: auto;
  nav {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  ul {
    margin: 0 80px;
    display: flex;
  }
  li {
    font-family: 'Nunito', sans-serif;
    font-size: 20px;
    color: #000000;
    margin-right: 50px;
    line-height: 1.35;
    img {
      width: 16px;
    }
    &:last-child {
      margin: 0;
      display: flex;
    }
    &.fadein-act {
      transition-delay: 1.5s;
      &:nth-child(2) {
        transition-delay: 1.7s;
      }
      &:nth-child(3) {
        transition-delay: 1.9s;
      }
      &:nth-child(4) {
        transition-delay: 2.1s;
      }
    }
  }
}

.fadein {
  transition: transform 1s, opacity 1s;
  transform: translate3d(0, 10px, 0);
  opacity: 0;
}

.fadein-act {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

@include sp-screen {
  #home .kv{
    background-image: url(../img/home-sp.jpg);
    height: auto;
  }
  #order .kv{
    background-image: url(../img/order-sp.jpg);
    height: auto;
  }
  .content {
    margin: 0 8% 20px;
    width: auto;
    .text {
      width: 100%;
      padding: 0;
    }
  }
  .footer {
    bottom: 0;
    position: fixed;
    nav {
      background-color: rgba(230, 225, 226, 0.85);
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
      padding: 14px 0 12px;
      opacity: 0;
      &.fadein-act {
        transition-delay: 1s;
        opacity: 1;
      }
    }
    ul {
      width: 297px;
      margin: auto;
      justify-content: space-around;
    }
    li {
      font-family: 'Nunito', sans-serif;
      font-size: 18px;
      margin-right: 40px;
    }
  }
}