@charset "UTF-8";

.pc-on {
  display: block;
}

.sp-on {
  display: none;
}

body {
  position: relative;
  @include fontSerif;
  font-size: 16px;
  color: $gray;
}

a {
  text-decoration: none;
  transition: all 0.5s;
  img {
    transition: opacity 0.5s;
    backface-visibility: hidden; 
  }
  &:hover {
    opacity: 0.7;
    text-decoration: none;
    img {
      opacity: 0.7;
    }
  }
}


input[type="text"],
input[type="email"],
input[type="tel"],
input[type="button"],
input[type="submit"],
input[type="password"],
button,
textarea,
select {
  appearance: none;
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
}

@include sp-screen {
  
  .pc-on {
    display: none;
  }

  .sp-on {
    display: block;
  }
  
}
